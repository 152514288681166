import { Box, Tab, Tabs } from "@mui/material"
import React from "react"

export default function NavTabs({ pos }) {
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        width: "100%",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Tabs
        value={pos}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="nav tabs example"
        sx={{
          "& .MuiTabs-flexContainer": {
            gap: "1rem",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "text.primary",
            height: "1px",
          },
        }}
      >
        <Tab
          label="Home"
          href="/"
          sx={{
            textTransform: "capitalize",
            fontWeight: 400,
            minWidth: "60px",
            "&.Mui-selected": { color: "text.primary" },
          }}
        />

        <Tab
          label="Tech"
          component="a"
          href="/tag/tech"
          sx={{
            textTransform: "capitalize",
            fontWeight: 400,
            minWidth: "60px",
            "&.Mui-selected": { color: "text.primary" },
          }}
        />
        <Tab
          label="About"
          active
          component="a"
          href="/about"
          sx={{
            textTransform: "capitalize",
            fontWeight: 400,
            minWidth: "60px",
            "&.Mui-selected": { color: "text.primary" },
          }}
        />
        <Tab
          label="Topics"
          component="a"
          href="/tags"
          sx={{
            textTransform: "capitalize",
            fontWeight: 400,
            minWidth: "60px",
            "&.Mui-selected": { color: "text.primary" },
          }}
        />
        <Tab
          label="My Links"
          component="a"
          href="/links"
          sx={{
            textTransform: "capitalize",
            fontWeight: 400,
            minWidth: "60px",
            "&.Mui-selected": { color: "text.primary" },
          }}
        />
      </Tabs>
    </Box>
  )
}
